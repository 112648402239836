<template>
  <telepresence :key="componentKey"></telepresence>
</template>

<script>
import telepresence from "@/components/telepresence.vue"
export default {
  name: "liveCall",
  components:{
    telepresence
  },
  data(){
    return{
      componentKey: 0
    }
  },
  methods:{
    forceRerender() {
      console.log('Force Redering the component')
      this.componentKey += 1;
    }
  }
};
</script>
